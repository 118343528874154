import React from "react"
import {Link} from "gatsby"
import { FaArrowRight } from "react-icons/fa"

import "../../scss/products.scss"
import "../../scss/yourvault.scss"

import SEO from "../../components/seo"
import HeaderLogoImg from "../../assets/img/compsol/vault_1.svg"
import HeaderImg from "../../assets/img/yourVault/Img1.png"
import Icon1 from "../../assets/img/yourVault/Icon1.png"
import Icon2 from "../../assets/img/yourVault/Icon2.png"
import Icon3 from "../../assets/img/yourVault/Icon3.png"
import Icon4 from "../../assets/img/yourVault/Icon4.png"
import Icon5 from "../../assets/img/yourVault/Icon5.png"
import Icon6 from "../../assets/img/yourVault/Icon6.png"
import Icon7 from "../../assets/img/yourVault/Icon7.png"
import Icon8 from "../../assets/img/yourVault/Icon8.png"
import Icon9 from "../../assets/img/yourVault/Icon9.png"
import Icon10 from "../../assets/img/yourVault/Icon10.png"
import Icon11 from "../../assets/img/yourVault/Icon11.png"
import Icon12 from "../../assets/img/yourVault/Icon12.png"
import GroupImg1 from "../../assets/img/yourVault/Img2.png"
import SingleForm from "../../components/singleForm"

const YourVaultPage = () => {
  return (
    <div>
      <SEO title="Yourvault" keywords={[`youpal`, `youpal group`, `yourVault`]} />
      <div className="yourVaultMain products">
        <section className="container sectionHead">
          <div className="row">
            <div className="col-12 order-1 order-md-1">
              <HeaderLogoImg className="headerLogo" />
              <div className="internalMainHeader bugP">
                <h2 className="internalNarrowCol">
                All Your IT Assets in One Place
                </h2>
              </div>
            </div>
            <div className="col-12 order-3 order-md-2">
              <p className="internalNarrowCol">
              Say goodbye to spreadsheets and say hello to a user-friendly Asset Management System. Our intuitive and feature-rich asset management system will provide you with a 360 view on all your IT assets. You’ll be able to increase efficiency and lower your costs.
              </p>
            </div>
            <div className="col-12 order-4 order-md-3">
              <Link to="/products/request-demo">
              <button type="button" className="btn cta btn-primary px-5 mt-5 mx-auto d-block">
                <span className="text-white">Free demo</span>
              </button>
              </Link>
            </div>
            <div className="col-12 order-2 order-md-4">
              <img className="headerImg" src={HeaderImg} />
            </div>
            
          </div>
        </section>

        <section className="container">
          <div className="col">
            <span className="internalNarrowCol internalSmallHeader">
              Solutions
            </span>
            <h3 className="internalNarrowCol">
            Our solution for your business
            </h3>
            <p className="internalNarrowCol">
            If you don’t have control over your assets, you may not have control over your costs. It is likely you are then paying for unnecessary licenses, subscriptions and hardware. How can you be sure you’re using your IT hardware and software efficiently? Let us help you.
            </p>
          </div>
        </section>

        <section className="container contactsSection">
          <div className="row">
            <div className="col">
              <div>
                <img src={Icon1} />
              </div>
              <h4>Accurate asset visibiliy</h4>
              <p>
              Whether they’re hardware assets or software licenses, the Yourvault asset management system lets you track and monitor all of your IT assets.
              </p>
            </div>
            <div className="col">
              <div>
                <img src={Icon2} />
              </div>
              <h4>Automatic scanning</h4>
              <p>
              You can schedule scans to update information related to the assets when needed. It will show new hardware and software. You can find your IT assets’ locations, their users, and other useful details such as IP address, refresh status, version, and so on.
              </p>
            </div>
            <div className="col">
              <div>
                <img src={Icon3} />
              </div>
              <h4>Managing licenses</h4>
              <p>
              We provide unique features to make dealing with multi-pack licenses more comfortable for you. In Yourvault, you can receive an email notification upon request each time one of your licenses gets close to the expiration date.
              </p>
            </div>
            <div className="col">
              <div>
                <img src={Icon4} />
              </div>
              <h4>QR code labels</h4>
              <p>
              Yourvault comes with an in-built QR code generator. It’s helpful for you to create QR code labels for easier access from mobile devices. The best part is that you can integrate it with most barcode scanner tools and QR code reader devices and apps.
              </p>
            </div>
          </div>
        </section>

        <section className="container internalSection">
          <div className="row TwoCol">
            <div className="col">
              <img className="sideImg" src={GroupImg1} />
            </div>
            <div className="col">
              <div className="col sideContent">
                <div className="internalSmallSecondaryHeader text-left">
                ANALYTICS
                </div>
                <h3 className="text-left">Get notified</h3>
                <p>
                Yourvault’s built-in and intuitive email notification system can be handy for notifying users and administrators.
                </p>
                <div className="row">
                    <div className="col squareHolderWrapper">
                        <div className="squareHolder"><img src={Icon5} /></div>
                        <h4>Notify users</h4>
                        <p>
                        •	Asset check-out and check-in <br/>
•	When a deadline for check-in is near, get a notification
 <br/>

                        </p>
                    </div>
                    <div className="col squareHolderWrapper">
                        <div className="squareHolder"><img src={Icon6} /></div>
                        <h4>Notify administrators</h4>
                        <p>
                        •	Asset check-in and check-out <br/>
•	Expected check-in report <br/>
•	Licenses that will expire soon <br/>


                        </p>
                    </div>
                </div>
                <div className="buttonArea">
                  
                <Link to="/products/request-demo">
                  <button type="button" className="btn btn-primary px-4">
                    <span>Try it out free</span>
                    <FaArrowRight style={{ fill: "white" }} />
                  </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="container exploreSection">
          <div className="row">
            <div className="col">
              <div className="internalSmallSecondaryHeader">FEATURES</div>
              <h3>Our features and your benefits</h3>
              <p>
              Oh, we can brag about our unique and flawless features and their possible contributions to your business all day. But here is a brief peek into them.
              </p>
              <div className="row mt-5">
                <div className="exploreFeature">
                  <div className="row">
                    <div>
                      <img src={Icon7} />
                    </div>
                    <div className="col sideContent">
                      <h4>Hosted in cloud</h4>
                        <p>
                        We offer a cloud platform with a 99% SLA uptime guarantee. Our endeavors to provide the best speed and reliability got us to the point where we have servers in international data centers all around the world. Our specialists handle all the server set-up and installation process for you.
                        </p>
                    </div>
                  </div>
                </div>
                <div className="exploreFeature">
                  <div className="row">
                    <div>
                      <img src={Icon8} />
                    </div>
                    <div className="col sideContent">
                      <h4>Security at the top</h4>
                        <p>
                        Our core principles include never giving in when it comes to security. Whether it’s our software solutions or cloud-hosting platform, we make sure they’re designed so that the security is unbreachable. Years of experience in building a secure environment enables us to implement best practices to prevent common cyber attacks.
                        </p>
                    </div>
                  </div>
                </div>
                <div className="exploreFeature">
                  <div className="row">
                    <div>
                      <img src={Icon9} />
                    </div>
                    <div className="col">
                      <div className="col sideContent">
                        <h4>Keeping it up-to-date</h4>
                        <p>
                        Treating our products as our children is a significant part of our lifestyle. We always take care of them and adapt them to the newest trends. Yourvault frequently gets updated releases. We fix bugs as soon as they’re reported and update them on a daily basis.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="exploreFeature">
                  <div className="row">
                    <div>
                      <img src={Icon10} />
                    </div>
                    <div className="col">
                      <div className="col sideContent">
                        <h4>Customer Support</h4>
                        <p>
                        Yourvault customer service representatives are working full-time to assist you whenever you need. We understand that IT asset management is highly technical and has all the resources ready to solve the problems you may face while using our products.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="exploreFeature">
                  <div className="row">
                    <div>
                      <img src={Icon11} />
                    </div>
                    <div className="col">
                      <div className="col sideContent">
                        <h4>API integration </h4>
                        <p>
                        You’ll simply love our JSON REST API. It lets you develop specific automation tailored to your needs. Thanks to it, you can perform nearly all the actions using your tools. 
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="exploreFeature">
                  <div className="row">
                    <div>
                      <img src={Icon12} />
                    </div>
                    <div className="col">
                      <div className="col sideContent">
                        <h4>Accessibility</h4>
                        <p>
                        At Yourvault, we always strive to provide even more comfort to our customers. As a result, we have mobile apps which you can use to update your assets on the go. You can also access our asset management software from any Windows, macOS, or Linux web server.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="container">
          <div className="row contactBox">
            <SingleForm resourceUrl="https://ma.youpal.se/form/generate.js?id=22" selector="contact-us-mautic-product-form" />
          </div>
        </section>
      </div>
    </div>
  )
}

export default YourVaultPage
